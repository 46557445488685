
<!-- 推荐 -->

<template>
    <div :class="$style.box" :style="{'left':clientWidth+'px' }">
      <div class="w-128 p-0-15 bg-f rad-2 m-b-20">
		<h1 class="f-14 bold h-55 l-h-55  tc b-b-e5" ><i class="iconfont b2b-lianxikefu classicon"></i> 联系客服</h1>
		<h1 class="f-14 bold h-55 l-h-55  tc" ><i class="iconfont b2b-dianhua classicon"></i> 电话热线</h1>
		<div class="b-b-e5 tc f-16 bold  p-b-15 m-b-15">
			<p class="classicon m-b-10 nowrap ">{{aftersalephone}}</p>
			<p class="classicon nowrap ">{{phone}}</p>
		</div>
      	<div class="tc">
			<img class="w-95 h-95" :src="$store.state.app.qrcodeurl" alt="">
			<p class="h-40 l-h-40 p-b-10">小程序商城</p>
		</div>
      </div>
	  <div class="h-50 l-h-50 tc bg-f cursor " id="return_top" @click="Backtops">
		 <i class="iconfont b2b-fanhuidingbu classicon"></i> 返回顶部
	  </div>
    </div>
</template>

<script>
export default {
    name: 'recommend',
	data() {
		return{
			aftersalephone:sessionStorage.getItem('aftersalephone'),
			phone:sessionStorage.getItem('phone')
		}
	},
    props: {
        formData: {
            type: Object,
            default: () => ({})
        }
    },
	watch:{
		formData:function(e){
			this.formData=e
			console.log('formData',e)
		},
		
		
	},
	computed:{
		clientWidth(){
			let Width
			if(location.href.indexOf('edit')==-1){
				Width=((document.documentElement.clientWidth-1200)/2)+60+1200
				console.log('Width',Width)
			}else{
				var dom = document.querySelector('.Editor_dragArea_1_sDW');
				console.log('dom',dom)
				if(dom){
					
					Width=((dom.offsetWidth-1200)/2)+1200+20
					console.log('Width',dom)
					console.log('dom',dom.offsetWidth);
				}
			}
			console.log('rWidth',Width)
			
			return Width
		},
	},
	methods: {
		
		
		// 文章更多
		Morenews: function Morenews(item) {
			this.$router.push({path:'/newslist',query:{type:item,showdetail:false}})
		},
		
		
		// 文章详情
		articledetail: function articledetail(id, type) {
			
		
		},
		
			
		Backtops(){
			this.Backtop()
			
			console.log(this.Backtop)
		}
	},
};
</script>

<style>
	/* .ViewComponentWrap_active_1ORx_, .viewBox .active:after */
	
</style>
<style module>
   .box{
		position: fixed;
		top: 0;
		bottom: 0;
		margin: auto;
		width: 128px ;
		height: 385px;
		z-index: 10;
   }
</style>
